import React from "react";

import Layout from "../../components/layout";
import { Seo } from "../../components";
import CouncilMembersList from "../../components/council-members-list";

import WithService from "../../service/withService";

const CouncilMembersListView = ({ loading, response, error }) => {
  return (
    <CouncilMembersList
      loading={loading}
      response={response}
      isProfileImage={false}
    />
  );
};

const CouncilMembers = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Diocesan Council Members" />
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="about-subtitle">Diocesan Council Members</h3>
              <WithService endpoint="diocesan-council-members">
                <CouncilMembersListView />
              </WithService>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CouncilMembers;
