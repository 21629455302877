import React from "react";
import Shimmer from "react-shimmer-effect";

const CouncilMembersList = ({
  loading,
  response,
  error,
  isProfileImage = true,
}) => {
  if (loading) {
    return (
      <Shimmer>
        <div className="about-profile-list-shimmer"></div>
      </Shimmer>
    );
  }
  if (response?.length > 0) {
    return response?.map((item, index) => {
      return (
        <div className="row mb-4" key={`${item.uuid}${index}`}>
          <div className="col-12">
            <div className="about-profile-list h-100">
              <div className="about-profile-list__content p-4">
                <p className="about-profile-list__content-title">
                  {item?.name}
                </p>
                <span className="about-profile-list__content-subtitle">
                  Members
                </span>
                {item?.members?.map((member, ind) => {
                  return (
                    <div
                      className="about-profile-list__content-member"
                      key={`${member.uuid}${ind}`}
                    >
                      <span className="about-profile-list__content-member-name">
                        {member.name}
                      </span>
                      &nbsp; - &nbsp;
                      <span className="about-profile-list__content-member-loc">
                        {member.location}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }
  return null;
};

export default CouncilMembersList;
